import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
import randomProducts from '~/graphql/queries/randomProducts.gql';
export default {
  apollo: {
    randomProducts: {
      query: randomProducts,
      result: function result(_ref) {
        var data = _ref.data;

        if (data.randomProducts) {
          this.randomProducts = this.mapRandomProducts(data.randomProducts);
        }
      }
    }
  },
  data: function data() {
    return {
      randomProducts: []
    };
  },
  methods: {
    mapRandomProducts: function mapRandomProducts(products) {
      return products.map(function (product) {
        return {
          id: product.id,
          title: product.title,
          slug: product.slug,
          img: {
            source: product.active_image ? product.active_image.url : ''
          },
          styleObject: {
            backgroundColor: product.color_code || '#fff'
          }
        };
      });
    }
  }
};