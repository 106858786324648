import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4f4eb3f9&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiHeadersHeaderHero: require('/data/www/buyanygiftcard.com/buyanygiftcard/nuxt/components/ui/Headers/HeaderHero.vue').default,IconsChevronRight: require('/data/www/buyanygiftcard.com/buyanygiftcard/nuxt/components/icons/ChevronRight.vue').default,UiButtonsButtonPrimary: require('/data/www/buyanygiftcard.com/buyanygiftcard/nuxt/components/ui/Buttons/ButtonPrimary.vue').default,UiHeroCardsRotated: require('/data/www/buyanygiftcard.com/buyanygiftcard/nuxt/components/ui/Hero/CardsRotated.vue').default,UiHero: require('/data/www/buyanygiftcard.com/buyanygiftcard/nuxt/components/ui/Hero/Hero.vue').default,IconsDeliveryCardGreen: require('/data/www/buyanygiftcard.com/buyanygiftcard/nuxt/components/icons/DeliveryCardGreen.vue').default,UiUspBox: require('/data/www/buyanygiftcard.com/buyanygiftcard/nuxt/components/ui/UspBox.vue').default,IconsBox: require('/data/www/buyanygiftcard.com/buyanygiftcard/nuxt/components/icons/Box.vue').default,IconsCreditCard: require('/data/www/buyanygiftcard.com/buyanygiftcard/nuxt/components/icons/CreditCard.vue').default,IconsDeliveryCar: require('/data/www/buyanygiftcard.com/buyanygiftcard/nuxt/components/icons/DeliveryCar.vue').default,UiCardOverview: require('/data/www/buyanygiftcard.com/buyanygiftcard/nuxt/components/ui/CardOverview.vue').default,UiInformationText: require('/data/www/buyanygiftcard.com/buyanygiftcard/nuxt/components/ui/InformationText.vue').default,UiCardBanner: require('/data/www/buyanygiftcard.com/buyanygiftcard/nuxt/components/ui/CardBanner.vue').default})
